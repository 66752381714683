$(document).ready(function () {

    $(document).on("click", function (event) {
        var $trigger = $(".dropdown");
        if ($trigger !== event.target && !$trigger.has(event.target).length) {
            $(".dropdown-menu-top").slideUp("fast");
        }
    });

});

//This is used to show the logout menu
function clickDropdownClass() {
    $(".dropdown").click(function () {
        $(this).find(".dropdown-menu-top").slideToggle("fast");
    });
}

function showJSToolTip(tagNameAttr) {
    let tag = $("input[name='" + tagNameAttr + "']");
    tag = tag.length === 0 ? $("textarea[name='" + tagNameAttr + "']") : tag;
    tag = tag.length === 0 ? $("select[name='" + tagNameAttr + "']") : tag;
    tag = tag.length === 0 ? $("mat-select[name='" + tagNameAttr + "']") : tag;

    if (tag.attr("alt") === "1") {
        return;
    }

    if (tag.val() == tag.attr("defaultValue")) {
        tag.val('').css({ 'color': '#000', 'font-style': 'normal' });
    }

    let nextTag = tag.next().is("span") === true ? tag.next() : tag.parent().next();
    nextTag.fadeIn(100);
}

function hideJSToolTip(tagNameAttr) {
    let tag = $("input[name='" + tagNameAttr + "']");
    tag = tag.length === 0 ? $("textarea[name='" + tagNameAttr + "']") : tag;
    tag = tag.length === 0 ? $("select[name='" + tagNameAttr + "']") : tag;
    tag = tag.length === 0 ? $("mat-select[name='" + tagNameAttr + "']") : tag;


    if (tag.val() == "") {
        tag.val($(this).attr("defaultValue")).css({ 'font-weight': 'normal', 'color': '#999' });
    }

    let nextTag = tag.next().is("span") === true ? tag.next() : tag.parent().next();
    nextTag.fadeOut(100);
}

function enableMobileToggle() {
    const hamburger = document.querySelector(".hamburger");
    const navLinks = document.querySelector(".nav-links");
    const links = document.querySelectorAll(".nav-links li");

    navLinks.classList.toggle("open");

    links.forEach(link => {
        link.classList.toggle("fade");
    });

    hamburger.classList.toggle("toggle");
}